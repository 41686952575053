/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SockJsClient from 'react-stomp';
import './ResolveSerieDisplay.scss';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import QuestionResolverWithCourse from 'app/question/QuestionResolver/QuestionResolverWithCourse/QuestionResolverWithCourse';
import SerieScore from './SerieScore';
import EndSerieConfirmationDialog from './EndSerieConfirmationDialog';

import { Badge, Button, Grid, Tooltip, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { resetQuestionsResolve } from 'app/redux/actions/Question/MultipleChoiceQuestion/MCQResolve/MCQResolve.actions';
import { selectStudentTask } from 'app/redux/selectors/Task/task.selector';
import { patchStudentTask } from 'app/redux/actions/Coaching/Tasks/Task.action';
import {
    selectIsExamMode,
    selectQuestionIndexToResolve,
    selectQuestionListToResolve
} from 'app/redux/selectors/Question/serie.selector';
import {
    setQuestionIndexToResolve,
    setQuestionListToResolve
} from 'app/redux/actions/Question/Serie/SerieGet/serieGet.actions';
import { openDrawer } from 'app/redux/actions/Drawer/drawer.actions';
import Chrono from 'app/components/Chrono/Chrono';
import { notificationSuccess } from 'app/redux/actions/Notification/notifications.actions';
import { END_OF_CANDIDATE_SERIE } from 'app/Snackbar/NotificationMessages';
import {
    patchPendingSerieCandidateIsDoneApi,
    patchPendingSerieDurationApi,
    patchPendingSerieDurationCandidateApi,
    patchPendingSerieIsDoneApi
} from 'app/api/historyApi';
import AuthenticationContainer from 'app/login/containers/AuthenticationContainer';
import { mapObjectToDuration } from 'app/utils/date.functions';
import { selectStudent } from '../../redux/selectors/Chapter/chapter.selector';
import { setDailySerieQuestions, resetDailySerieQuestions } from 'app/redux/actions/Question/History/history.actions.js';
import { selectInitialQuestionList, selectQuestionStatList } from 'app/redux/selectors/Question/history.selector';
import { postInteractiveQuizCurrentQuestionValidatedByLeaderApi, postSendSerieScoreApi } from 'app/api/interactiveQuizApi';
import { getAverageScoreWithQuestionHistories } from './SerieScore.function';
import { WebSocketActionsEnum } from 'app/utils/WebsocketActionsEnum';

const useStyles = makeStyles(() => ({
    serieToResolve: {
        display: 'flex',
        padding: '20px 0',
        height: '100%'
    },
    button: {
        borderRadius: '8px',
        padding: '6px 12px'
    },
    quizContainer: {
        margin: 0
    },
    leaderSidebar: {
        rowGap: '16px'
    },
    playerIcon: {
        minWidth: 'fit-content'
    },
    playersIcons: {
        rowGap: '16px',
        columnGap: '16px'
    },
    userIcon: {
        borderRadius: '50%',
        width: '40px',
        height: '40px'
    }
}));

function ResolveSerieDisplay(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const sizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));

    const initialQuestionList = useSelector(selectInitialQuestionList);
    const isExamMode = useSelector(selectIsExamMode);
    const listTaskStudent = useSelector(selectStudentTask);
    const questionIndexToResolve = useSelector(selectQuestionIndexToResolve);
    const questionListToResolve = useSelector(selectQuestionListToResolve);
    const questionStatList = useSelector(selectQuestionStatList);
    const studentSelected = useSelector(selectStudent);

    const currentUserId = () => props.candidateUuid ? undefined : AuthenticationContainer.getCurrentUserId();

    const [goodAnswers, setGoodAnswers] = useState(props.goodAnswers ? props.goodAnswers : 0);
    const [isScoreOpen, setIsScoreOpen] = useState(false);
    const [isEndSerieConfirmationOpen, setIsEndSerieConfirmationOpen] = useState(false);
    const [maxDuration, setMaxDuration] = useState(props.maxDuration);
    const [usersInRoom, setUsersInRoom] = useState(props.usersInRoom ?? []);

    const [interactiveQuizAnswers, setInteractiveQuizAnswers] = useState(questionListToResolve[questionIndexToResolve]?.answers);
    const [imagesWithAnswersSelected, setImagesWithAnswersSelected] = useState(usersInRoom?.map((user) => ({
        imageUrl: user.imageUrl,
        selectedAnswerIds: []
    })));
    const [isQuestionValidatedByLeader, setIsQuestionValidatedByLeader] = useState(false);
    const [quizResults, setQuizResults] = useState([]);

    const getCurrentUserId = () => AuthenticationContainer.getCurrentUserId();
    const maxDurationRef = useRef(maxDuration);

    const isLastQuestion = (
        questionIndexToResolve === questionListToResolve.length - 1
    );

    const isChronoFinished = (
        maxDuration?.seconds === 0 &&
        maxDuration?.minutes === 0 &&
        maxDuration?.hours <= 0
    );

    const onCloseQuiz = async() => {
        if(props.serieHistoryUpdate) {
            if(props.candidateUuid) {
                props.onFinishCandidateSerie();
                await patchPendingSerieCandidateIsDoneApi(props.serieHistoryUpdate.id, props.candidateUuid);
                dispatch(notificationSuccess(END_OF_CANDIDATE_SERIE));
                return;
            }
            await patchPendingSerieIsDoneApi(props.serieHistoryUpdate.id, currentUserId());
        }

        setIsScoreOpen(true);

        if(studentSelected && listTaskStudent) {
            let taskFound = null;

            if(props.serieId) {
                taskFound = listTaskStudent.find((element) => (element.serieId === props.serieId));
            } else if(props.chapterId) {
                taskFound = listTaskStudent.find((element) => (element.chapterId === props.chapterId));
            }

            if(taskFound) {
                dispatch(patchStudentTask(taskFound.id, getCurrentUserId()));
            }
        }

    };

    const onEndSerieClick = () => {
        setIsEndSerieConfirmationOpen(true);
    };

    const saveDurationLeft = async() => {
        if(maxDurationRef.current !== null) {
            if(props.candidateUuid) {
                await patchPendingSerieDurationCandidateApi({
                    serieHistoryId: props.serieHistoryUpdate?.id,
                    durationLeft: mapObjectToDuration(maxDurationRef.current)
                }, props.candidateUuid);
            } else if(props.maxDuration) {
                await patchPendingSerieDurationApi({
                    serieHistoryId: props.serieHistoryUpdate?.id,
                    userId: currentUserId(),
                    durationLeft: mapObjectToDuration(maxDurationRef.current)
                });
            }
        }
    };

    useEffect(() => {
        setUsersInRoom(props.usersInRoom?.map((user) => ({
            ...user,
            answers: [],
            validatedQuestion: Boolean(usersInRoom.find((userInRoom) => userInRoom.id === user.id)?.validatedQuestion)
        })));
    }, [props.usersInRoom]);

    useEffect(() => {
        if(sizeUpLg) {
            dispatch(openDrawer());
        }
    }, [sizeUpLg]);

    useEffect(() => {
        if(isChronoFinished) {
            onCloseQuiz();
        }
    }, [isChronoFinished]);

    useEffect(() => {
        saveDurationLeft();
        window.addEventListener('beforeunload', saveDurationLeft);
        return () => {
            window.removeEventListener('beforeunload', saveDurationLeft);
            if(props.type === 'DAILY') {
                dispatch(resetDailySerieQuestions());
            }
        };
    }, []);

    useEffect(() => {
        maxDurationRef.current = maxDuration;
    }, [maxDuration]);

    useEffect(() => {
        if(props.type === 'DAILY') {
            dispatch(setDailySerieQuestions(questionListToResolve));
        }
    }, []);

    useEffect(() => {

        const currentQuestionList = initialQuestionList?.map((question) => question.id === questionListToResolve[questionIndexToResolve].id
            ? { ...question, correctlyAnswered: 'TRUE' }
            : question);

        if(currentQuestionList?.length) {
            dispatch(setDailySerieQuestions(currentQuestionList));
        }
    }, [goodAnswers]);

    useEffect(() => {
        if(usersInRoom && usersInRoom.length) {
            const updatedUsers = usersInRoom.map((user) => {
                const clonedAnswers = questionListToResolve[questionIndexToResolve].answers.map((answer) => ({ ...answer }));
                return {
                    ...user,
                    answers: clonedAnswers,
                    validatedQuestion: false
                };
            });
            setUsersInRoom(updatedUsers);
            setInteractiveQuizAnswers(questionListToResolve[questionIndexToResolve].answers);
            setIsQuestionValidatedByLeader(false);
        }
    }, [questionListToResolve[questionIndexToResolve].answers]);

    const removeQuestionFromList = () => {
        const copy = [...questionListToResolve];
        copy.splice(questionIndexToResolve, 1);
        dispatch(setQuestionListToResolve(copy));
    };

    const toNextQuestion = () => {
        if(isLastQuestion) {
            return;
        }

        dispatch(resetQuestionsResolve());
        if(questionListToResolve[questionIndexToResolve].isAnswered) {
            removeQuestionFromList();
        } else {
            dispatch(setQuestionIndexToResolve(questionIndexToResolve + 1));
        }

    };

    const toPreviousQuestion = () => {
        if(questionIndexToResolve > 0) {
            dispatch(resetQuestionsResolve());
            dispatch(setQuestionIndexToResolve(questionIndexToResolve - 1));
            if(questionListToResolve[questionIndexToResolve].isAnswered) {
                removeQuestionFromList();
            }
        }
    };

    const handleEndSerieCancelClick = () => {
        setIsEndSerieConfirmationOpen(false);
    };

    const handleEndSerieConfirmClick = () => {
        setIsEndSerieConfirmationOpen(false);
        setIsScoreOpen(true);
        const scoreData = {
            open: true,
            nbTotalQuestions: props.nbTotalQuestions,
            serieHistoryId: props.serieHistoryUpdate?.id,
            redirect: props.redirect,
            quizResults
        };
        if(props.quizUuidFromLeader) {
            postSendSerieScoreApi(props.quizUuidFromLeader, scoreData);
        }
        if(props.onCloseQuiz) {
            props.onCloseQuiz();
        } else if(props.onFinishCandidateSerie) {
            props.onFinishCandidateSerie();
        } else {
            onCloseQuiz();
        }
    };

    const incrGoodAnswers = () => {
        setGoodAnswers((prevState) => (prevState + 1));
    };

    const handleQuestionMark = () => {
        const copy = [...questionListToResolve];
        copy[questionIndexToResolve].isMark = !copy[questionIndexToResolve].isMark;
        dispatch(setQuestionListToResolve(copy));
    };

    const handleValidateQuestionCandidate = () => {
        if(isLastQuestion && questionListToResolve.length === 1) {
            onCloseQuiz();
        } else if(isLastQuestion) {
            toPreviousQuestion();
        } else {
            toNextQuestion();
        }
    };

    const handleValidateQuestion = () => {
        questionListToResolve[questionIndexToResolve].isAnswered = true;
        if(props.candidateUuid || isExamMode) {
            handleValidateQuestionCandidate();
        }
        if(!props.candidateUuid && isLastQuestion && props.serieHistoryUpdate && questionListToResolve.length === 1) {
            patchPendingSerieIsDoneApi(props.serieHistoryUpdate.id, currentUserId());
        }
    };

    const handleShowAnswersLeaderButton = () => {
        if(props.quizUuidFromLeader) {
            postInteractiveQuizCurrentQuestionValidatedByLeaderApi(
                props.quizUuidFromLeader,
                questionListToResolve[questionIndexToResolve].id
            );
        }
        const allAnswers = interactiveQuizAnswers.map((answer) => ({ ...answer }));
        const updatedQuizResults = [...quizResults];

        usersInRoom.forEach((user) => {
            const userAnswers = user.answers.map((userAnswer) => ({ ...userAnswer }));
            userAnswers.forEach((userAnswer) => {
                const matchingAnswerIndex = allAnswers.findIndex((answer) => answer.id === userAnswer.id);
                if(matchingAnswerIndex !== -1 && userAnswer.selected) {
                    allAnswers[matchingAnswerIndex].selected = true;
                }
            });
            user.answers = userAnswers;
            user.validatedQuestion = true;
            updatedQuizResults.push({
                userId: user.id,
                correctAnswer: user.correctAnswer
            });
        });
        setQuizResults(updatedQuizResults);
        setInteractiveQuizAnswers(allAnswers);
        setIsQuestionValidatedByLeader(true);
        questionListToResolve[questionIndexToResolve].isAnswered = true;
    };

    return (
        <>
            <SockJsClient
                url={`${process.env.REACT_APP_BACK_END}websocket`}
                topics={[`/quiz/${props.quizUuidFromLeader}`]}
                onMessage={(msg) => {
                    const { action, userId, selectedAnswerIds, isCorrectAnswer } = msg;
                    if(action === WebSocketActionsEnum.USER_ANSWERED_CURRENT_QUESTION) {
                        const selectedAnswerIdsAsNumbers = selectedAnswerIds?.map(Number);
                        setUsersInRoom((prevUsers) => prevUsers.map((user) => {
                            if(user.id === userId) {
                                const updatedAnswers = user.answers.map((answer) => {
                                    if(selectedAnswerIdsAsNumbers.includes(answer.id)) {
                                        return { ...answer, selected: true };
                                    }
                                    return answer;
                                });
                                return {
                                    ...user,
                                    answers: updatedAnswers,
                                    validatedQuestion: true,
                                    correctAnswer: isCorrectAnswer
                                };
                            }
                            return user;
                        }));
                        setImagesWithAnswersSelected((prevImages) => {
                            const updatedImages = prevImages.map((imageInfo) => {
                                if(imageInfo.imageUrl === usersInRoom.find((user) => user.id === userId)?.imageUrl) {
                                    return {
                                        imageUrl: imageInfo.imageUrl,
                                        selectedAnswerIds
                                    };
                                }
                                return imageInfo;
                            });
                            return updatedImages;
                        });
                    }
                }}
            />

            <div className={classes.serieToResolve} data-testid="resolve-serie-display-serie">
                { props.maxDuration &&
                    <Chrono
                        isRunning={!isChronoFinished}
                        maxDuration={maxDuration}
                        setMaxDuration={setMaxDuration}
                    />
                }

                <Grid container spacing={1} justifyContent="center" className={classes.quizContainer}>
                    <Grid item xs="auto" sm={10}>
                        <QuestionResolverWithCourse
                            className="multipleChoiceQuestion"
                            questionAsked={questionListToResolve[questionIndexToResolve]}
                            serieHistoryUpdate={props.serieHistoryUpdate}
                            questionIndex={questionIndexToResolve}
                            questionsNumber={props.questionsList.length}
                            isLastQuestion={isLastQuestion}
                            candidateUuid={props.candidateUuid}
                            onEndSerieClick={onEndSerieClick}
                            onQuestionMark={handleQuestionMark}
                            onNextQuestion={toNextQuestion}
                            onPreviousQuestion={toPreviousQuestion}
                            onIncrGoodAnswers={incrGoodAnswers}
                            onValidateCandidateQuestion={handleValidateQuestion}
                            saveDurationLeft={saveDurationLeft}
                            isExamMode={isExamMode}
                            quizUuidFromLeader={props.quizUuidFromLeader}
                            interactiveQuizAnswers={props.quizUuidFromLeader ? interactiveQuizAnswers : []}
                            imagesWithAnswersSelected={isQuestionValidatedByLeader ? imagesWithAnswersSelected : []}
                            chapterId = {props.chapterId}
                        />
                    </Grid>
                    {props.quizUuidFromLeader && (
                        <Grid item className={classes.leaderSidebar}
                            container direction="column" alignItems="center" alignContent="flex-start"
                            wrap="nowrap"
                            xs="auto" sm={2}
                        >
                            <Grid item>
                                <Button
                                    className={`${classes.button} validateButton`}
                                    color="primary"
                                    data-testid="validate-button"
                                    disabled={!usersInRoom?.every((user) => user.validatedQuestion)}
                                    onClick={handleShowAnswersLeaderButton}
                                    variant="contained"
                                >
                                    Afficher les réponses
                                </Button>
                            </Grid>
                            <Grid item className={classes.playersIcons}
                                container direction={usersInRoom.length > 12 ? 'row' : 'column' }
                                alignItems="center" alignContent="center" justifyContent="center"
                            >
                                {usersInRoom.map((user) => (
                                    <Grid item key={user.id} className={classes.playerIcon}>
                                        <Tooltip title={`${user.firstName} ${user.lastName}`}>
                                            <Badge overlap="rectangular" badgeContent={user.validatedQuestion
                                                ? (
                                                    <div className="checkmark">
                                                        <CheckCircleIcon style={{ color: 'blue' }} />
                                                    </div>
                                                )
                                                : (
                                                    <div className="waiting">
                                                        <HourglassEmptyIcon color="primary"/>
                                                    </div>
                                                )}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }}>
                                                <img
                                                    className={classes.userIcon}
                                                    src={user.imageUrl}
                                                    alt={`User ${user.id}`}
                                                    data-testid={`test-open-${user.id}`}
                                                />
                                            </Badge>
                                        </Tooltip>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </div>

            <EndSerieConfirmationDialog
                open={isEndSerieConfirmationOpen}
                handleEndSerieCancelClick={handleEndSerieCancelClick}
                handleEndSerieConfirmClick={handleEndSerieConfirmClick}
            />

            { isScoreOpen
                ? <SerieScore
                    averageScore={getAverageScoreWithQuestionHistories(questionStatList)}
                    candidateUuid={props.candidateUuid}
                    nbTotalQuestions={props.nbTotalQuestions}
                    quizResults={quizResults}
                    quizUuidFromLeader={props.quizUuidFromLeader}
                    redirect={props.redirect}
                    serieHistoryId={props.serieHistoryUpdate?.id}
                    type={props.type}
                    usersInRoom={props.usersInRoom}
                />
                : <></>
            }
        </>
    );
}

ResolveSerieDisplay.propTypes = {
    maxDuration: PropTypes.object,
    serieId: PropTypes.number,
    chapterId: PropTypes.number,
    questionsList: PropTypes.array.isRequired,
    nbTotalQuestions: PropTypes.number.isRequired,
    serieHistoryUpdate: PropTypes.object,
    redirect: PropTypes.string,
    candidateUuid: PropTypes.string,
    goodAnswers: PropTypes.number,
    onCloseQuiz: PropTypes.func,
    onFinishCandidateSerie: PropTypes.func,
    type: PropTypes.string,
    quizUuidFromLeader: PropTypes.string,
    usersInRoom: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string
    }))
};

export default ResolveSerieDisplay;
